import { Injectable } from '@angular/core'
import { LocalStorageService } from '../../../../../../services/local-storage/local-storage.service'
import { TableColumnsModel } from '../../../../../../common/models/ui/table.model'
import { LOCAL_STORAGE_KEYS } from '../../../../../../services/local-storage/local-storage.key'

export type TableKey = 'leads'

@Injectable({
  providedIn: 'root'
})
export class TableColumnsService {

  constructor(
    private readonly localStorage: LocalStorageService
  ) {}

  public setConfig(tableKey: TableKey, columns: TableColumnsModel[]): void {
    if (tableKey) {
      const existingConfig = this.localStorage.getItem(LOCAL_STORAGE_KEYS.TABLE_COLUMNS) || {}
      existingConfig[tableKey] = columns
      this.localStorage.setItem(LOCAL_STORAGE_KEYS.TABLE_COLUMNS, existingConfig)
    } else {
      throw new Error('Missing table key')
    }
  }

  public deleteConfig(tableKey: TableKey): void {
    if (tableKey) {
      const existingConfig = this.localStorage.getItem(LOCAL_STORAGE_KEYS.TABLE_COLUMNS)
      if (existingConfig && existingConfig.hasOwnProperty(tableKey)) delete existingConfig[tableKey]
      this.localStorage.setItem(LOCAL_STORAGE_KEYS.TABLE_COLUMNS, existingConfig)
    } else {
      throw new Error('Missing table key')
    }
  }

  public getConfig(tableKey: TableKey, availableColumns: TableColumnsModel[]): TableColumnsModel[] | undefined {
    const existingConfig = this.localStorage.getItem(LOCAL_STORAGE_KEYS.TABLE_COLUMNS) || {}
    const config: { field: string }[] = existingConfig[tableKey]
    if (config && config.length) {
      const mappedConfig: TableColumnsModel[] = []
      config.forEach((configCol: { field: string }) => {
        const matchedCol: TableColumnsModel | undefined = availableColumns.find((aCol: TableColumnsModel) => aCol.field === configCol.field)
        if (matchedCol) mappedConfig.push(matchedCol)
      })
      return mappedConfig
    }
    return []
  }

}
