import {IAffiliateModel} from "./affiliate.model";
import {IBrandModel} from "./brand.model";
import {ILeadModel} from "./lead.model";
import {IBrandIntegrationModel} from "./brandIntegration.model";
import {IVerticalTemplateModel} from "./verticalTemplate.model";

export enum EOfferType {
  CPA = 'CPA',
  CPL = 'CPL',
}

export enum EOfferCurrency {
  USD = 'USD',
  EUR = 'EUR',
}

export interface IBox {
  id: string;
  name: string;
  isSendable: boolean;
  isDuplicateBlockEnabled?: boolean;
  isFallbackEnabled?: boolean;
  code: string;
  description: string;
  verticalTemplateId: string;
  companyId?: string;
  createdAt: Date;
  updatedAt: Date;
  copiedFrom?: string;
}

export interface IBoxModel extends IBox {
  affiliates?: IAffiliateModel[];
  brokers?: IBrandModel[];
  leads?: ILeadModel[];
  integrations?: IBrandIntegrationModel[];
  vertical?: IVerticalTemplateModel;
}

export interface IBoxAffiliate {
  id?: string;
  boxId?: string;
  affiliateId?: string;
  weight: number | string;
  priceBuy: number | string;
  priceSell: number | string;
  offerType?: EOfferType;
  offerCurrency?: EOfferCurrency;
}

export interface IBoxAffiliateModel extends IBoxAffiliate {
  affiliate: IAffiliateModel;
  box: IBoxModel;
}
