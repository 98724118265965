export const LOCAL_STORAGE_KEYS = {
  LANGUAGE: 'systemLanguage',
  SESSION_ID: 'sessionKey',
  REDIRECT_AFTER: 'redirectAfter',
  COMPANY: 'company',
  PAGE_SIZE: 'pageSize',
  DG_FILTERS_EXPANDED: 'dataGridFiltersExpanded',
  LAST_ENTITY_VISITED: 'lastEntityVisited',
  TABLE_COLUMNS: 'tableColumns',
  METRICS_CONFIG: 'metricsConfig'
}
