import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { LOCAL_STORAGE_KEYS } from '../local-storage/local-storage.key'
import { LocalStorageService } from '../local-storage/local-storage.service'
import { ApiService } from '../api/api.service'
import { LANG, LangType } from '../../common/models/lang.model'

@Injectable()
export class I18nService {
  private readonly langList: string[]

  constructor(
    private readonly translate: TranslateService,
    private readonly localStorage: LocalStorageService,
    private readonly apiService: ApiService
  ) {
    this.langList = Object.values(LANG)
  }

  public get defaultLang(): string {
    return this.translate.defaultLang
  }

  public get otherLanguages(): string[] {
    return this.langList.filter((l: string) => l !== this.defaultLang)
  }

  public setupLanguages(): void {
    this.translate.setDefaultLang(this.getLanguage())
  }

  public getLanguage(): string {
    let userLang: string | undefined = this.defaultLang
      || this.localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE) as string
      || this.translate.getBrowserLang() as string
    return this.langList.indexOf(userLang as LangType) > -1 ? userLang : this.langList[0]
  }

  public switchLanguage(lang: string): void {
    this.translate.setDefaultLang(lang)
    this.localStorage.setItem(LOCAL_STORAGE_KEYS.LANGUAGE, lang)
    this.apiService.updateMyAccount({ lang: lang === 'uk' ? 'ua' : lang }).subscribe()
  }

}
