import {ICountry} from "../models/domain/models";
import {ICompany} from "../models/domain/models/company.model";
import * as latinize from 'latinize';
import {KeyValue} from "@angular/common";

export class MapHelper {

  public static mapEntitiesFullname(entities: any[]): any[] {
    if (entities?.length) {
      return entities.map((entities: []) => {
        return MapHelper.mapEntityFullname(entities);
      })
    }
    return entities;
  }

  public static mapEntityFullname(entity: any): any {
    if (entity) {
      return {
        ...entity,
        fullName: MapHelper.mapFullname(entity)
      }
    }
    return entity;
  }

  public static mapCompany(company: ICompany, countries: ICountry[]): ICompany {
    const matchCountry: ICountry | undefined = countries.find((c: ICountry) => company.countryId === c.id);
    return {
      ...company,
      countryCode: matchCountry?.iso,
      countryNicename: matchCountry?.nicename
    }
  }

  public static mapFullname(res: Partial<{ firstName: string, lastName: string }>): string {
    if (res.firstName && res.lastName) {
      return latinize(`${res.firstName} ${res.lastName}`)
    }
    return res.firstName || res.lastName || '👽';
  }

  public static mapEnumDisplayKeyValue(enm: Object, i18nKey: string = 'common'): KeyValue<string, string>[] {
    return Object.values(enm).map((s: string): KeyValue<string, string> => ({
      key: `${i18nKey}.${s}`,
      value: s
    }));
  }

}
