import { ILeadFraudCheckMeta } from './lead.model'

export const enum EPhoneLineType {
  TollFree = "Toll Free",
  Mobile = "Mobile",
  Wireless = "Wireless",
  Landline = "Landline",
  Satellite = "Satellite",
  VOIP = "VOIP",
  PremiumRate = "Premium Rate",
  Pager = "Pager",
  NA = "N/A"
}

export interface IPhoneCheckServiceInfo {
  success: boolean;
  request_id: string;
  message: string;
  valid: boolean;
  fraud_score: number; // expected < 20, 0-20 = 0%, 21-30 = 20%, 31-40=30%, 41-50=40%, 51-60=50%, 61-70=70%, 71-80=90%, >80=100%
  recent_abuse: boolean; // expected false, true = 20%
  VOIP: boolean; // expected false, true = 100%
  prepaid: boolean; // expected false, true = 100%
  risky: boolean; // expected false, true = 50%
  active: boolean; // expected false, true = 100%
  spammer: boolean; // expected false, true = 90%
  line_type: EPhoneLineType; // expected Mobile or Wireless, VOIP, TollFree, Pager = 100%, N/A = 70%, others = 60%
  carrier: string;
  country: string;
  city: string;
  active_status: string;
  leaked: boolean;
  do_not_call: boolean;
}

export interface IEmailCheckServiceInfo {
  success: boolean;
  request_id: string;
  message: string;
  valid: boolean; // expected true, false = 100%
  fraud_score: number; // expected < 75, 0-20 = 0%, 21-30 = 7%, 31-40=15%, 41-50=20%, 51-60=30%, 61-70=40%, 71-80=50%, >80=80%
  disposable: boolean; // expected false, true = 50%
  smtp_score: number; // expected 3, <3 = 30%
  overall_score: number; // expected 4, <4 = 30%
  dns_valid: boolean; // expected true, false = 80%
  domain_age: {
    human: string;
    timestamp: number;
    iso: Date; // should be > 1 year, < 1 year = 30%
  };
  first_name: string;
  generic: boolean;
  common: boolean;
  honeypot: boolean;
  deliverability: "low" | 'medium' | 'high';
  frequent_complainer: boolean;
  spam_trap_score: "none" | "low" | 'medium' | 'high';
  catch_all: boolean;
  timed_out: boolean;
  suspect: boolean;
  recent_abuse: boolean;
  suggested_domain: string;
  leaked: boolean;
  first_seen: {
    human: string;
    timestamp: number;
    iso: Date;
  };
  sanitized_email: string;
  domain_velocity: "none" | "low" | 'medium' | 'high';
  user_activity: "none" | "low" | 'medium' | 'high';
  associated_names: {
    status: string;
    names: string[]
  };
  associated_phone_numbers: {
    status: string;
    phone_numbers: string[]
  };
}

export interface IIPCheckServiceInfo {
  request_id: string;
  success: boolean;
  message: string;
  fraud_score: number; // expected < 75, 0-30 = 0%, 31-40 = 7%, 41-50=15%, 51-60=25%, 61-70=33%, 71-80=45%, >80=75%
  is_crawler: boolean; // expected false, true = 25%
  proxy: boolean; // expected false, true = 80%
  vpn: boolean; // expected false, true = 50%
  tor: boolean; // expected false, true = 50%
  active_vpn: boolean; // expected false, true = 50%
  active_tor: boolean; // expected false, true = 50%
  recent_abuse: boolean; // expected false, true = 50%
  bot_status: boolean; // expected false, true = 100%
  mobile: boolean;
  country_code: string;
  region: string;
  city: string;
  ISP: string;
  ASN: number;
  organization: string;
  timezone: string;
  host: string;
  connection_type: string;
  abuse_velocity: string;
  zip_code: string;
  latitude: number;
  longitude: number;
}

export enum EFraudCheckResultStatus {
  Unknown = 'unknown',
  Clear = 'clear',
  Warn = 'warn',
  Attention = 'attention',
  Risky = 'risky'
}

export interface IPhoneCheckResult {
  fraudScore: number;
  status: EFraudCheckResultStatus;
  isValid: boolean;
  isActive: boolean;
  isSpammer: boolean;
  isVOIP: boolean;
  lineType: string;
  carrier: string;
}

export interface IEmailCheckResult {
  fraudScore: number;
  status: EFraudCheckResultStatus;
  isValid: boolean;
  isDisposable: boolean;
  smtpStatus: number;
  validityStatus: number;
  domainCreated: Date;
}

export interface IIPCheckResult {
  fraudScore: number;
  status: EFraudCheckResultStatus;
  isVPN: boolean;
  isProxy: boolean;
  isTor: boolean;
  isBot: boolean;
  isMobile: boolean;
  isCrawler: boolean;
}

export interface IComplexCheckParams {
  geo: string;
  phone: string;
  email: string;
  ip: string;
}

export interface IComplexCheckOptions {
  phone: boolean; // default is false
  email: boolean; // default is false
  ip: boolean; // default is false
}

export interface IComplexCheckResult {
  fraudCheckedAt: Date;
  totalFraudScore: number;
  fraudCheckResult?: ILeadFraudCheckMeta;
}
