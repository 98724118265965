<div class="wrapper">
  <img src="../../../assets/images/9.png" alt="">
  <div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center">
      <div class="col-6"></div>
      <div class="col-6">
        <div class="text">
          <h1>{{'common.notFoundTitle' | translate}}</h1>
          <p>{{'common.notFoundDesc' | translate}}</p>
          <button
            routerLink="/"
            mat-flat-button
            color="primary"
            type="submit">
            {{'common.goHome' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
