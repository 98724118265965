import {MissingTranslationHandler, MissingTranslationHandlerParams} from "@ngx-translate/core";
import {StringHelper} from "../../common/static/string.helper";

export class MissingTranslationService implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    /*const handledKey: string | undefined = /[^.]*$/.exec(params.key)?.[0];
    return handledKey ? StringHelper.camelCaseToTitleCase(handledKey) : (params.key || 'Can`t resolve missing i18n key');*/
    const handledKey: string | undefined = /[^.]*$/.exec(params.key)?.[0];
    return handledKey ? handledKey : (params.key || 'Can`t resolve missing i18n key');
  }
}
