import { RequestFilterTypeEnum, RequestFilterKeyEnum } from '../../common/models/request-filter.enum'
import { Sort } from '@angular/material/sort'
import { CursorSearchRequestParams, OffsetSearchRequestParams } from '../../common/models/search-request-params.model'
import { ELeadChangesHistoryCategory } from '../../common/models/domain/models'
import { EFraudCheckResultStatus } from '../../common/models/domain/models/fraud-check.model'

export class ApiHelper {

  public static getOffsetSearchUrl(params: Partial<OffsetSearchRequestParams>): string {
    let url = ApiHelper.getOffsetSearchUrlWithParams(params.url as string, params.skip as number, params.take as number)
    return ApiHelper.getUrlWithParamsGroup(url, params)
  }

  public static getCursorSearchUrl(params: Partial<CursorSearchRequestParams>): string {
    let url: string = ApiHelper.getCursorSearchUrlWithParams(params.url as string, params.direction, params.nextPageId)
    return ApiHelper.getUrlWithParamsGroup(url, params)
  }

  public static getUrlWithParamsGroup(url: string, params: Partial<OffsetSearchRequestParams> | Partial<CursorSearchRequestParams>) {
    url = ApiHelper.getUrlWithParams(url, params.sort, RequestFilterTypeEnum.SORT, params.sortKeyWrapper)
    url = ApiHelper.getUrlWithParams(url, params.filters, RequestFilterTypeEnum.FILTER, params.filtersKeyWrapper)
    url = ApiHelper.getUrlWithParams(url, params.scale, RequestFilterTypeEnum.SCALE)
    url = ApiHelper.getUrlWithParams(url, params.text, RequestFilterTypeEnum.TEXT)
    return url
  }

  public static getOffsetSearchUrlWithParams(url: string, skip: number, take: number): string {
    if (url?.length && skip != null && take != null) {
      url += `?${ RequestFilterKeyEnum.SKIP }=${ skip }&${ RequestFilterKeyEnum.TAKE }=${ take }`
    }
    return url
  }

  public static getCursorSearchUrlWithParams(url: string, direction?: 'prev' | 'next', nextPageId?: string): string {
    if (url?.length && direction != null && nextPageId != null) {
      url += `?${ direction }=${ nextPageId }`
    }
    return url
  }

  public static getUrlWithParams(
    url: string,
    filters: any,
    type: RequestFilterTypeEnum.FILTER | RequestFilterTypeEnum.SORT | RequestFilterTypeEnum.TEXT | RequestFilterTypeEnum.SCALE,
    keyWrapper?: { [key: string]: string[] }
  ): string {
    if (url?.length && filters && Object.keys(filters)?.length) {
      url += url.indexOf('?') !== -1 ? '&' : '?'
      if (type === RequestFilterTypeEnum.TEXT || type === RequestFilterTypeEnum.SCALE) {
        url += `${ type }=${ filters }&`
      } else if (type === RequestFilterTypeEnum.SORT) {
        const sort: Sort = filters
        if (sort && sort.direction?.length) {
          if (keyWrapper) {
            for (const kw in keyWrapper) {
              keyWrapper[kw].forEach((field) => {
                const fieldPath = `${ kw }.${ field }`
                if (fieldPath === sort.active) {
                  const sortActiveKeys: string[] = sort.active.split('.')
                  let sortActiveUrl: string = ''
                  sortActiveKeys.forEach((sak, index) => {
                    if (!index) {
                      sortActiveUrl += `${ sak }[${ type }]`
                    } else {
                      sortActiveUrl += `[${ sak }]`
                    }
                  })
                  url += `${ sortActiveUrl }[gte]=${ sort.direction }&${ sortActiveUrl }[lte]=${ sort.direction }&`
                } else {
                  url += `${ type }[${ sort.active }]=${ sort.direction }&`
                }
              })
            }
          } else {
            url += `${ type }[${ sort.active }]=${ sort.direction }&`
          }
        }
      } else {
        for (const prop in filters) {
          if (filters[prop] == null) {
            break
          } else if (typeof filters[prop] === 'string' || typeof filters[prop] === 'number' || typeof filters[prop] === 'boolean') {
            if (prop === 'totalFraudScore') {
              switch (filters[prop]) {
                case EFraudCheckResultStatus.Risky:
                  url += 'filter[totalFraudScore][gte]=61&filter[totalFraudScore][lte]=100&'
                  break
                case EFraudCheckResultStatus.Attention:
                  url += 'filter[totalFraudScore][gte]=31&filter[totalFraudScore][lte]=60&'
                  break
                case EFraudCheckResultStatus.Warn:
                  url += 'filter[totalFraudScore][gte]=11&filter[totalFraudScore][lte]=30&'
                  break
                case EFraudCheckResultStatus.Clear:
                  url += 'filter[totalFraudScore][gte]=0&filter[totalFraudScore][lte]=10&filter[isFraudChecked]=true&'
                  break
                case EFraudCheckResultStatus.Unknown:
                  url += 'filter[isFraudChecked]=false&'
                  break
              }
            } else if (prop == RequestFilterKeyEnum.CATEGORY) {
              url += `${ prop }=${ ELeadChangesHistoryCategory[filters[prop]] }&`
            } else {
              if (keyWrapper) {
                for (const wrapperKey in keyWrapper) {
                  const propsToWrap: string[] = keyWrapper[wrapperKey]
                  if (propsToWrap.indexOf(prop) != -1) {
                    url += `${ wrapperKey }[${ type }][${ prop }]=${ filters[prop] }&`
                  } else {
                    url += `${ type }[${ prop }]=${ filters[prop] }&`
                  }
                }
              } else {
                url += `${ type }[${ prop }]=${ filters[prop] }&`
              }
            }
          } else if (Object.keys(filters[prop])?.length) {
            const nested = filters[prop]
            for (const nestedProp in nested) {
              if (keyWrapper) {
                for (const wrapperKey in keyWrapper) {
                  const propsToWrap: string[] = keyWrapper[wrapperKey]
                  if (propsToWrap.indexOf(nestedProp) != -1) {
                    if (typeof nested[nestedProp] === 'object' && nested[nestedProp] !== null) {
                      const deepNested = nested[nestedProp]
                      for (const deepNestedProp in deepNested) {
                        url += `${ wrapperKey }[${ type }][${ nestedProp }][${ deepNestedProp }]=${ deepNested[deepNestedProp] }&`
                      }
                    } else {
                      url += `${ wrapperKey }[${ type }][${ nestedProp }]=${ nested[nestedProp] }&`
                    }
                  } else {
                    url += `${ type }[${ prop }][${ nestedProp }]=${ nested[nestedProp] }&`
                  }
                }
              } else {
                url += `${ type }[${ prop }][${ nestedProp }]=${ nested[nestedProp] }&`
              }
            }
          }
        }
      }
      if (url[url.length - 1] === '&') {
        url = url.slice(0, -1)
      }
    }
    return url
  }

}
