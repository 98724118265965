import {ErrorHandler, Inject, Injectable} from '@angular/core';
import {ToastService} from "../toast/toast.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private toastService: ToastService,
    @Inject(Window) private windowRef: Window
  ) {
  }

  public handleError(error: any): void {
    if (/ChunkLoadError/.test(error?.message)) {
      if (this.windowRef.navigator.onLine) {
        this.showErrorToast('errors.serviceUnavailable');
      } else {
        this.showErrorToast('errors.connectionLost');
      }
    } else {
      const isAboutSvgIcon = !!error.message.match('Unable to find icon with the name');
      const isAboutCdr = !!error.message.match('ExpressionChangedAfterItHasBeenCheckedError')
      !isAboutCdr && !isAboutSvgIcon && this.showErrorToast('errors.occurred');
    }
    console.error(error);
  }

  protected showErrorToast(msg: string): void {
    this.toastService.show({
      i18nKey: msg,
      type: "error",
      duration: 10000,
      dismissible: true
    });
  }

}
