export const APP_ROUTES = {
  DASHBOARD: 'dashboard',
  LEADS: 'leads',
  ACCOUNTS: 'accounts',
  AFFILIATES: 'affiliates',
  BRANDS: 'brands',
  HUBS: 'hubs',
  INTEGRATION: 'integration',
  AUTH: 'auth',
  SIGN_IN: 'sign-in',
  LIST: 'list',
  SCENARIOS: 'scenarios',
  CREATE: 'create',
  POSTBACK: 'postback',
  ATTACH_BRAND_INTEGRATION: 'attach-brand-integration',
  ATTACH_AFFILIATE: 'attach-affiliate',
  VERTICALS: 'verticals',
  CONVERSIONS: 'conversions',
  SETTINGS: 'settings',
  METRICS: 'metrics',
  STATUS_GROUPS: 'status-groups',
  COUNTRY_GROUPS: 'country-groups'
}
