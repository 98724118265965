import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { OffsetSearchRequestParams } from '../models/search-request-params.model'
import { Observable } from 'rxjs'
import { ISearchResponseDTO } from '../models/domain/dto'
import { ApiHelper } from '../../services/api/api.helper'

@Injectable({
  providedIn: 'root'
})
export class ABaseApiService {
  protected api: string

  constructor(
    protected readonly http: HttpClient,
    @Inject(Window) protected windowRef: Window
  ) {
    this.api = `https://${ this.getApiDomain() }/api`
  }

  private getApiDomain(): string {
    switch (this.windowRef.location.hostname) {
      case 'client.hypernet.pro':
        return 'sys.hypernet.pro'
      case 'localhost':
       /* return 'host.localmock.com'*/
      case 'dev-client.hypernet.pro':
        return 'dev-sys.hypernet.pro'
      default:
        return this.windowRef.location.hostname
    }
  }

  protected getOffsetSearchRequest(params: Partial<OffsetSearchRequestParams>): Observable<any> {
    return this.http.get<ISearchResponseDTO<any>>(ApiHelper.getOffsetSearchUrl(params))
  }
}
