import * as moment from 'moment'
import { Moment } from 'moment'
import { DateRangeType } from '../models/ui/date.model'

export class DateHelper {

  public static get fullFormat(): string {
    return 'dd.MM.yyyy HH:mm:ss'
  }

  public static get shortFormat(): string {
    return 'dd.MM.yyyy'
  }

  public static getDateRange(today: Date, range: DateRangeType): [Moment, Moment] {
    const todayMoment: Moment = moment(today)
    const todayMomentEnd: Moment = moment(today).endOf('day')
    const dateRange: [Moment] | [Moment, Moment] = [todayMomentEnd]
    if (range === 'd') {
      dateRange.unshift(todayMoment.startOf('day'))
    } else if (range === 'w') {
      dateRange.unshift(todayMoment.subtract(1, 'weeks'))
    } else if (range === 'm') {
      dateRange.unshift(todayMoment.subtract(1, 'months'))
    }
    return dateRange as unknown as [Moment, Moment]
  }

  public static getTimeSlots(): string[] {
    return [
      ...Array(24 * 4).fill(0).map((_, i) => {
        return ('0' + ~~(i / 4) + ':0' + 60 * (i / 4 % 1)).replace(/\d(\d\d)/g, '$1')
      })
    ]
  }

  public static getTimezone(): string | null {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (e) {
      return null
    }
  }

  public static mergeDateTime(date: string, time: string): string | undefined {
    if (date && time) {
      const [hours, minutes] = time.split(':')
      const mergedDate: Date = new Date(date)
      mergedDate.setHours(+hours)
      mergedDate.setMinutes(+minutes)
      return moment(mergedDate).toISOString(true)
    }
    return date
  }

}
