import { Inject, Injectable } from '@angular/core'
import { LOCAL_STORAGE_KEYS } from '../local-storage/local-storage.key'
import { LocalStorageService } from '../local-storage/local-storage.service'

@Injectable()
export class AppInitService {

  constructor(
    @Inject(Window)
    private readonly windowRef: Window,
    private readonly localStorage: LocalStorageService
  ) {
  }

  public init(): void {
    const url: URL = new URL(this.windowRef.location.href)
    const encodedSid: string | null = url.searchParams.get('sid')
    if (encodedSid) {
      this.localStorage.setEncodedItem(LOCAL_STORAGE_KEYS.SESSION_ID, encodedSid)
      this.windowRef.history.pushState({}, this.windowRef.document.title, this.windowRef.location.pathname)
    }
  }

}
