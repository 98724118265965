import {NgModule} from '@angular/core';
import {Route, RouterModule} from '@angular/router';
import {APP_ROUTES} from './common/models/app-routes.model';
import {NotFoundComponent} from "./components/not-found/not-found.component";
import {AuthGuard} from "./guards/auth.guard";

const routes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: APP_ROUTES.DASHBOARD
  },
  {
    path: APP_ROUTES.AUTH,
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: APP_ROUTES.DASHBOARD,
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
