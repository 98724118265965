import {IBrandModel} from "./brand.model";
import {IBrandIntegrationModel} from "./brandIntegration.model";
import {IBoxModel} from "./box.model";

export enum ECapType {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  OneOff = 'oneOff'
}

export interface IDaySchedule {
  isEnabled: boolean,
  startTime: string, // "00:00"
  endTime: string // "00:00"
}

export interface IBoxBrandSchedule {
  isEnabled: boolean;
  schedule: {
    timezone: string,
    monday: IDaySchedule,
    tuesday: IDaySchedule,
    wednesday: IDaySchedule,
    thursday: IDaySchedule,
    friday: IDaySchedule,
    saturday: IDaySchedule,
    sunday: IDaySchedule
  };
};

export interface IBoxBrokerGeoConfig {
  enabled: boolean;
  countries: string[];
  languages: string[];
}

export interface IBoxBrokerOfferConfig {
  enabled: boolean;
  name: string[];
}

export interface IBoxBrand {
  id: string;
  brokerId: string;
  boxId: string;
  integrationId: string;
  weight: number;
  priority: number;
  cap: number;
  capType: ECapType;
  percentFiled: number;
  registeredLeads: number;
  isSendable: boolean;
  isStrict: boolean;
  schedule: IBoxBrandSchedule | null;
  geoConfig: IBoxBrokerGeoConfig;
  offerConfig: IBoxBrokerOfferConfig;
  createdAt: Date;
  updatedAt: Date;
}

export interface IBoxBrandModel extends IBoxBrand {
  box?: IBoxModel;
  broker?: IBrandModel;
  integration?: IBrandIntegrationModel;
}
