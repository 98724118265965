import { ELeadStatus } from "./lead.model";
import {IBrandIntegrationModel} from "./brandIntegration.model";


export type RequestParamOption = { source: ERequestParamOptionSource, value: string | {[key: string]: RequestParamOption}, params?: string }

export enum ERequestSuccessCondition {
  Eq = "=",
  NotEq = "!=",
  Gt = ">",
  Lt = "<",
  Exists = 'ex',
  NotExists = 'nex',
}

export type RequestParams = [string, RequestParamOption][];
export type RequestHeaders = [string, RequestParamOption][];
export type RequestSuccessConditions = [string, ERequestSuccessCondition, string][];
export type RequestDataOptions = { [key: string]: RequestParamOption };
export type ResponseBody = { [key: string]: string | number | boolean };
export type RequestStatusMap = { [key: string]: ELeadStatus };
export type RequestConnectionOptions = { [key: string]: string | number | boolean };

export enum ERequestType {
  Get = "get",
  Post = "post",
  Put = "put",
  Patch = "patch"
}

export enum ERequestParamOptionSource {
  ConnectionOptions = "connectionOptions",
  Lead = "lead",
  Profile = "profile",
  Function = "function",
  Nested = "nested",
  Plain = "plain"
}

export enum EIntegrationTemplateStatus {
  Draft = "draft",
  Public = "public",
  Archived = "archived"
}

export enum ERequestBodyType {
  Json = "json",
  Urlencoded = "urlencoded",
  Form = "form-data",
  None = "none",
}

export enum EDataType {
  Object = "object",
  Array = "array",
}

export enum ELeadOptionsPlace {
  Query = "query",
  Body = "body",
}

export interface IRequest {
  url: RequestParamOption | string;
  requestType: ERequestType;
  bodyType: ERequestBodyType;
  body?: RequestDataOptions;
  params: RequestDataOptions;
  headers: RequestDataOptions;
  successCondition?: RequestSuccessConditions;
}

export interface IRedirectConfig {
  url: string;
}

export interface IResponse {
  path: string;
  type: EDataType;
  schema: ResponseBody;
}

export interface IPostbackConfig extends IRequest {
  statusMap?: RequestStatusMap;
}

export interface IPushRequest extends IRequest {
  leadOptionPlace?: ELeadOptionsPlace;
  redirect?: IRedirectConfig;
  response: IResponse;
}

export interface IPullLeadRequest extends IRequest {
  response: IResponse;
}

export interface IPullFTDRequest extends IRequest {
  response: IResponse;
}

export interface IIntegrationConfig {
  push: IPushRequest;
  pullLead: IPullLeadRequest;
  pullFTD: IPullFTDRequest;
  connectionOptions: RequestConnectionOptions;
}

export interface IIntegrationTemplateConfig {
  id: string;
  status: EIntegrationTemplateStatus;
  config: IIntegrationConfig;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

export interface IIntegrationTemplateConfigModel extends IIntegrationTemplateConfig {
  integrations?: IBrandIntegrationModel[];
}
