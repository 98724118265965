import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {map, Observable} from "rxjs";
import {IAccountModel} from "../common/models/domain/models";
import {APP_ROUTES} from "../common/models/app-routes.model";
import {AuthService} from "../services/auth/auth.service";
import {AccountService} from "../services/account/account.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private accountService: AccountService
  ) {
  }

  canActivate(): Observable<boolean> {
    return this.authService.getAccount()
      .pipe(
        map((res: IAccountModel) => {
          if (res) {
            this.accountService.account$.next(res);
            return true;
          } else {
            this.router.navigate([`/${APP_ROUTES.AUTH}/${APP_ROUTES.SIGN_IN}`]);
            return false;
          }
        })
      )
  }

}
