import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { catchError, forkJoin, map, Observable, of, tap } from 'rxjs'
import { APP_ROUTES } from '../../common/models/app-routes.model'
import { ApiService } from '../api/api.service'
import { LOCAL_STORAGE_KEYS } from '../local-storage/local-storage.key'
import { ISessionCreateDTO } from '../../common/models/domain/dto'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { IAccountModel, IAccountRoleModel } from '../../common/models/domain/models'
import { ICompanyModel } from '../../common/models/domain/models/company.model'
import { MapHelper } from '../../common/static/map.helper'
import { ToastService } from '../toast/toast.service'
import { LocalStorageService } from '../local-storage/local-storage.service'
import { PortalSettingsModel } from '../../common/models/domain/models/portalSettings.model'
import {
  TableColumnsService,
  TableKey
} from '../../modules/dashboard/shared/components/modals/column-settings/table-columns.service'

@Injectable()
export class AuthService {

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialogRef: MatDialog,
    private toastService: ToastService,
    private localStorage: LocalStorageService,
    private tableColumnsService: TableColumnsService
  ) {
  }

  public getAccount(): Observable<IAccountModel> {
    return forkJoin([
      this.apiService.getAccount(),
      this.apiService.getPortalSettings()
        .pipe(
          catchError(() => of({} as PortalSettingsModel)),
          tap((settings: PortalSettingsModel) => {
            if (settings && Object.keys(settings).length) {
              Object.keys(settings).forEach((key) => {
                if (key.match('TableConfig')) {
                  const tableKey: TableKey = key.replace('TableConfig', '') as TableKey
                  if (settings[key] && settings[key].length) {
                    this.tableColumnsService.setConfig(tableKey, settings[key])
                  }
                } else if (key === 'dashboardConfig') {
                  this.localStorage.setItem(LOCAL_STORAGE_KEYS.METRICS_CONFIG, settings[key])
                }
              })
            }
          })
        )
    ]).pipe(
      map(([account, settings]: [IAccountModel, PortalSettingsModel]) => {
        const companies: ICompanyModel[] | any = account.roles
          ?.map((role: IAccountRoleModel) => {
            return role.company
              ? {
                ...role.company,
                role: role.type
              }
              : undefined
          })
          ?.filter((c: ICompanyModel | undefined) => !!c)
        let currentCompany = this.localStorage.getItem(LOCAL_STORAGE_KEYS.COMPANY)
        if (!currentCompany) {
          currentCompany = companies?.find((c: ICompanyModel) => !!c)
        }
        return {
          ...account,
          companies,
          currentCompany,
          fullName: MapHelper.mapFullname(account)
        }
      })
    )
  }

  public signIn(accountCredentials: ISessionCreateDTO): Observable<{ id: string }> {
    return this.apiService.signIn(accountCredentials).pipe(
      tap(({ id }: { id: string }) => {
        this.localStorage.setItem(LOCAL_STORAGE_KEYS.SESSION_ID, id)
      }),
      tap(() => {
        const redirectRoute = this.localStorage.getItem(LOCAL_STORAGE_KEYS.REDIRECT_AFTER)
        if (redirectRoute) {
          this.router.navigate([redirectRoute]).then(() => {
            this.localStorage.removeItem(LOCAL_STORAGE_KEYS.REDIRECT_AFTER)
          })
        } else {
          this.router.navigate([`/${ APP_ROUTES.DASHBOARD }`])
        }
      }),
      tap(() => {
        this.toastService.close()
      })
    )
  }

  public getSessionKey(): string {
    return this.localStorage.getItem(LOCAL_STORAGE_KEYS.SESSION_ID)
  }

  public logout(redirectAfter?: boolean): void {
    this.localStorage.removeItem(LOCAL_STORAGE_KEYS.SESSION_ID)
    this.localStorage.removeItem(LOCAL_STORAGE_KEYS.REDIRECT_AFTER)

    if (redirectAfter && this.router.url.match(APP_ROUTES.DASHBOARD)) {
      this.localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_AFTER, this.router.url)
    }
    this.dialogRef.closeAll()
    this.router.navigate([`/${ APP_ROUTES.AUTH }/${ APP_ROUTES.SIGN_IN }`])
  }

  public fullLogout(): void {
    this.localStorage.clear()
    this.dialogRef.closeAll()
    this.router.navigate([`/${ APP_ROUTES.AUTH }/${ APP_ROUTES.SIGN_IN }`])
  }

}
