import { Component, OnInit } from '@angular/core'
import { I18nService } from './services/i18n/i18n.service'
import { IconsService } from './services/icons/icons.service'
import { Title } from '@angular/platform-browser'
import { environment } from '../environments/environment.prod'
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard'
import { ToastService } from './services/toast/toast.service'
import { autoTakeUntil } from './common/rxjs/auto-take-until'

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  constructor(
    private langService: I18nService,
    private iconsService: IconsService,
    private titleService: Title,
    private clipboardService: ClipboardService,
    private toastService: ToastService
  ) {
    this.langService.setupLanguages()
    this.iconsService.setupUiKitIcons()
    this.titleService.setTitle(environment.siteName)
  }

  ngOnInit(): void {
    this.clipboardService.copyResponse$
      .pipe(autoTakeUntil(this))
      .subscribe({
        next: ({ isSuccess }: IClipboardResponse) => {
          isSuccess && this.toastService.show({
            i18nKey: 'common.copiedToClipBoard',
            type: 'success',
            duration: 2000
          })
        }
      })
  }

}
