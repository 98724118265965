import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class MapService {

  public mapFullname(obj: any): string | null {
    if (obj && obj.firstName && obj.lastName) {
      return `${ obj.firstName } ${ obj.lastName }`
    }
    return null
  }

}
