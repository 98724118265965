export enum ApiGroup {
  TRACKER = 'tracker',
  INJECTOR = 'injector'
}

export enum TrackerApiRoute {
  SESSION = 'session',
  LEAD = 'lead',
  STATUS = 'status',
  RAW = 'raw',
  TEST = 'test',
  DEPOSIT = 'deposit',
  COMPANY = 'company',
  RESOURCES = 'resources',
  SETTINGS = 'settings',
  ARCHIVE = 'archive',
  ROLE = 'role',
  HISTORY = 'history',
  INJECT = 'inject',
  AFFILIATE = 'affiliate',
  ASSIGN = 'assign',
  BRAND = 'broker',
  AFFILIATE_ATTACHMENT = 'affiliateAttachment',
  BRAND_ATTACHMENT = 'brokerAttachment',
  INTEGRATION = 'integration',
  BOX = 'box',
  CLEAR = 'clear',
  ACCOUNT = 'account',
  LOOKUP = 'lookup',
  LIST = 'list',
  MY = 'my',
  PROFILE = 'profile',
  CHANGE_PASS = 'change-password',
  INTEGRATOR = 'integrator',
  GEO = 'geo',
  COUNTRIES = 'countries',
  LANGUAGES = 'languages',
  TIMEZONES = 'timezones',
  VERTICAL_TEMPLATE = 'verticalTemplate',
  AUTH = 'auth',
  PRESET = 'preset',
  LEAD_OPTIONS = 'leadOptions',
  REQUEST_HISTORY = 'requestHistory',
  POSTBACK = 'postback',
  REPORT = 'report',
  DOWNLOAD = 'download',
  CONVERSION = 'conversion',
  HOLD = 'hold',
  BOT_SUBSCRIBER = 'botSubscriber',
  APPROVE = 'approve',
  DECLINE = 'decline',
  SUBSCRIPTION = 'subscription',
  ACTIVATE = 'activate',
  BLOCK = 'block',
  REGISTRATION = 'registration'
}

export enum InjectorApiRoute {
  SCENARIO = 'scenario',
  START = 'start',
  STOP = 'stop'
}
