<div class="row align-items-center gap-h-6">
  <div class="col-auto">
    <mat-spinner
      [diameter]="20">
    </mat-spinner>
  </div>
  <div class="col-auto">
    {{message}}
  </div>
</div>
