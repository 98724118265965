export enum RequestFilterTypeEnum {
  FILTER = 'filter',
  SORT = 'sort',
  TEXT = 'text',
  SCALE = 'scale'
}

export enum RequestFilterKeyEnum {
  SKIP = 'skip',
  TAKE = 'take',
  CREATED_AT = 'createdAt',
  DATE = 'date',
  GTE = 'gte',
  LTE = 'lte',
  STATUS = 'status',
  STATE = 'state',
  CATEGORY = 'category',
  GEO = 'geo',
  LANG = 'lang',
  ROLE = 'role',
  AFFILIATE_ID = 'affiliateId',
  BROKER_ID = 'brokerId',
  HUB_ID = 'boxId',
  VERTICAL_ID = 'verticalTemplateId',
  IS_TEST = 'isTest',
  COMPANY_ID = 'companyId',
  STATUS_GROUPS = 'brandStatusGroupId',
  TOTAL_FRAUD_SCORE = 'totalFraudScore',
  FUNNEL = 'funnel'
}
