import { Injectable } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { svgIconNames } from './icons-list'

@Injectable()
export class IconsService {

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
  }

  public setupUiKitIcons(): void {
    svgIconNames.forEach((iconName: string) => {
      const iconPath: string = `assets/svg-icons/${ iconName }-icon.svg`
      this.matIconRegistry.addSvgIcon(iconName, this.domSanitizer.bypassSecurityTrustResourceUrl(iconPath))
    })
  }

}
